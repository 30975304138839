import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, Button, Grid, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { cpf, cnpj } from 'cpf-cnpj-validator';

const RegisterForm = ({ isEdit = false, onSubmit, clearForm }) => {
  const [dataUser, setDataUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const schema = Yup.object().shape({
    NM_CLIENTE: Yup.string().min(3, 'Mínimo 3 caracteres').required('Razão Social é obrigatória'),
    NM_FANTASIA: Yup.string().min(3, 'Mínimo 3 caracteres').required('Nome Fantasia é obrigatório'),
    NR_CPFCNPJ: Yup
        .string()
        .test(
          'is-valid-cpf-cnpj',
          'CPF/CNPJ inválido',
          (value) => cpf.isValid(value) || cnpj.isValid(value)
        )
        .required('CPF/CNPJ é obrigatório'),
    NR_IE: Yup.string().required('Inscrição Estadual é obrigatória'),
    DS_EMAIL: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
    DS_EMAIL2: Yup.string().email('E-mail inválido'),
    NM_CONTATO: Yup.string().required('Contato é obrigatório'),
    NR_FONE1: Yup.string().required('Telefone é obrigatório'),
    IN_WHATSAPP1: Yup.string().required('É WhatsApp?'),
    NR_FONE2: Yup.string(),
    IN_WHATSAPP2: Yup.string(),
    NM_ENDERECO: Yup.string(),
    DS_LOGRADOURO: Yup.string(), 
    NR_PREDIO: Yup.string(), 
    NM_BAIRRO: Yup.string(), 
    NM_CIDADE: Yup.string(), 
    NR_CEP: Yup.string(), 
    NM_UF: Yup.string(),
    DS_COMPLEMENTO: Yup.string(),
    DS_REFERENCIA: Yup.string(),
    DS_SENHA: Yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres').required('Senha é obrigatória'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('DS_SENHA'), null], 'As senhas devem ser iguais')
      .required('Confirmação de senha é obrigatória'),
    agreeDataAccuracy: Yup.boolean().oneOf([true], 'Você deve confirmar que os dados são verdadeiros'),
    agreeCNPJRequirement: Yup.boolean().oneOf([true], 'Você deve concordar que é obrigatório possuir CNPJ'),
  });

  const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: dataUser,
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userDataLogin')) || {};
    if (clearForm) {
      reset({
        NM_CLIENTE: '',
        NM_FANTASIA: '',
        NR_CPFCNPJ: '',
        NR_IE: '',
        DS_EMAIL: '',
        DS_EMAIL2: '',
        NM_CONTATO: '',
        NR_FONE1: '',
        IN_WHATSAPP1: 'N',
        NR_FONE2: '',
        IN_WHATSAPP2: 'N',
        DS_COMPLEMENTO: '',
        DS_REFERENCIA: '',
        NM_UF: '', // Ensure NM_UF is included in default values
        agreeDataAccuracy: false,
        agreeCNPJRequirement: false,
      }); // Limpar o formulário
    } else if (Object.keys(userData).length > 0) {
      setDataUser(userData);
      reset(userData); // Atualizar os valores do formulário
    }
  }, [clearForm, reset]);

  useEffect(() => {
    const updateFormData = () => {
      const userData = JSON.parse(localStorage.getItem('userDataLogin')) || {};
      if (clearForm) {
        reset({
          NM_CLIENTE: '',
          NM_FANTASIA: '',
          NR_CPFCNPJ: '',
          NR_IE: '',
          DS_EMAIL: '',
          DS_EMAIL2: '',
          NM_CONTATO: '',
          NR_FONE1: '',
          IN_WHATSAPP1: 'N',
          NR_FONE2: '',
          IN_WHATSAPP2: 'N',
          DS_COMPLEMENTO: '',
          DS_REFERENCIA: '',
          NM_UF: '', // Ensure NM_UF is included in default values
          agreeDataAccuracy: false,
          agreeCNPJRequirement: false,
        }); // Limpar o formulário
      } else if (Object.keys(userData).length > 0) {
        setDataUser(userData);
        reset(userData); // Atualizar os valores do formulário
      }
    };

    updateFormData();

    if (!clearForm) {
      const handleStorageChange = (event) => {
        if (event.key === 'userDataLogin') {
          updateFormData();
        }
      };

      window.addEventListener('storage', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, [clearForm, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* Dados Pessoais */}
        <Grid item xs={12} md={12}>
          <TextField
            label="Razão Social"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            {...register('NM_CLIENTE')}
            error={!!errors.NM_CLIENTE}
            helperText={errors.NM_CLIENTE?.message}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Nome Fantasia"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            {...register('NM_FANTASIA')}
            error={!!errors.NM_FANTASIA}
            helperText={errors.NM_FANTASIA?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
            <Controller
            name="NR_CPFCNPJ"
            control={control}
            render={({ field, fieldState }) => {
                const applyMask = (value) => {
                const rawValue = value.replace(/\D/g, '');
                return rawValue.replace(
                    rawValue.length > 11
                    ? /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/
                    : /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                    rawValue.length > 11 ? '$1.$2.$3/$4-$5' : '$1.$2.$3-$4'
                );
                };

                return (
                <TextField
                    {...field}
                    label="CPF/CNPJ"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onBlur={(e) => field.onChange(applyMask(e.target.value))}
                />
                );
            }}
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Inscrição Estadual"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            {...register('NR_IE')}
            error={!!errors.NR_IE}
            helperText={errors.NR_IE?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="E-mail"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            {...register('DS_EMAIL')}
            error={!!errors.DS_EMAIL}
            helperText={errors.DS_EMAIL?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="E-mail Secundário"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            {...register('DS_EMAIL2')}
            error={!!errors.DS_EMAIL2}
            helperText={errors.DS_EMAIL2?.message}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Nome para Contato"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
            {...register('NM_CONTATO')}
            error={!!errors.NM_CONTATO}
            helperText={errors.NM_CONTATO?.message}
          />
        </Grid>
        {/* Telefone e WhatsApp */}
        <Grid item xs={8} md={6}>
          <Controller
            name="NR_FONE1"
            control={control}
            render={({ field, fieldState }) => (
              <InputMask {...field} mask="(99) 99999-9999" onChange={field.onChange}>
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label="Telefone"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            name="IN_WHATSAPP1"
            control={control}
            defaultValue={dataUser.IN_WHATSAPP1 || "N"} // Garantir valor padrão
            render={({ field, fieldState }) => (
              <TextField
                select
                label="É WhatsApp?"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                size="small"
                {...field}
                value={field.value || "N"} // Garantir que o valor seja exibido
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={8} md={6}>
          <Controller
            name="NR_FONE2"
            control={control}
            render={({ field, fieldState }) => (
              <InputMask {...field} mask="(99) 99999-9999" onChange={field.onChange}>
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    label="Telefone Secundário"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Controller
            name="IN_WHATSAPP2"
            control={control}
            defaultValue={dataUser.IN_WHATSAPP2 || "N"} // Garantir valor padrão
            render={({ field, fieldState }) => (
              <TextField
                select
                label="É WhatsApp?"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                size="small"
                {...field}
                value={field.value || "N"} // Garantir que o valor seja exibido
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        {/* Endereço */}
        {!isEdit && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome do Endereço"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('NM_ENDERECO')} // Alterado de NM_ENDERECO para addressName
                error={!!errors.NM_ENDERECO}
                helperText={errors.NM_ENDERECO?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="NR_CEP" // Alterado de NR_CEP para zipCode
                control={control}
                render={({ field, fieldState }) => (
                  <InputMask {...field} mask="99999-999" onChange={field.onChange}>
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="CEP"
                        fullWidth
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Cidade"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('NM_CIDADE')} // Alterado de NM_CIDADE para city
                error={!!errors.NM_CIDADE}
                helperText={errors.NM_CIDADE?.message}
              />
            </Grid>
            <Grid item xs={4}>
            <TextField
                    select
                    label="UF"
                    {...register('NM_UF')}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    error={!!errors.NM_UF}
                    helperText={errors.NM_UF?.message}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8, // 4.5 items with some padding
                          },
                        },
                      },
                    }}
                  >
                    {[
                      'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 
                      'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
                    ].map((uf) => (
                      <MenuItem key={uf} value={uf}>
                        {uf}
                      </MenuItem>
                    ))}
                  </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Bairro"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('NM_BAIRRO')} // Alterado de NM_BAIRRO para neighborhood
                error={!!errors.NM_BAIRRO}
                helperText={errors.NM_BAIRRO?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Rua"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('DS_LOGRADOURO')} // Alterado de DS_LOGRADOURO para street
                error={!!errors.DS_LOGRADOURO}
                helperText={errors.DS_LOGRADOURO?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Número do Prédio"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('NR_PREDIO')} // Alterado de NR_PREDIO para buildingNumber
                error={!!errors.NR_PREDIO}
                helperText={errors.NR_PREDIO?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('DS_COMPLEMENTO')}
                error={!!errors.DS_COMPLEMENTO}
                helperText={errors.DS_COMPLEMENTO?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Referência"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...register('DS_REFERENCIA')}
                error={!!errors.DS_REFERENCIA}
                helperText={errors.DS_REFERENCIA?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Senha"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                {...register('DS_SENHA')}
                error={!!errors.DS_SENHA}
                helperText={errors.DS_SENHA?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Confirmar Senha"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                {...register('confirmPassword')}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                        {showConfirmPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label>
                <input
                  type="checkbox"
                  {...register('agreeDataAccuracy')}
                />
                Revisei os dados inseridos neste cadastro e confirmo que são verdadeiros
              </label>
              {errors.agreeDataAccuracy && (
                <p style={{ color: 'red', fontSize: '0.8rem' }}>{errors.agreeDataAccuracy.message}</p>
              )}
            </Grid>
            <Grid item xs={12}>
              <label>
                <input
                  type="checkbox"
                  {...register('agreeCNPJRequirement')}
                />
                É obrigatório possuir CNPJ para ser um revendedor Elite Comandos
              </label>
              {errors.agreeCNPJRequirement && (
                <p style={{ color: 'red', fontSize: '0.8rem' }}>{errors.agreeCNPJRequirement.message}</p>
              )}
            </Grid>
          </>
        )}
        {/* Agreement Fields */}

        {/* Botão de Ação */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ backgroundColor: 'black', '&:hover': { backgroundColor: '#333' } }} // Cor de fundo preta
          >
            {isEdit ? 'Atualizar' : 'Cadastrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterForm;
